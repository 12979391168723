import React from "react"
import Events from "../../core/classes/events"

/** StickySnackbar component */
class StickySnackbarComponent extends React.Component {
  constructor(props) {
    super(props)

    this.events = new Events()

    this.state = {
      shown: false,
    }
  }

  componentDidMount() {
    const { hiddenBeforeElement, hiddenAfterElement } = this.props

    this.events.listen("scroll", () => {
      const before = document.querySelector(hiddenBeforeElement)
      const after = document.querySelector(hiddenAfterElement)

      let shown = true

      if (before instanceof HTMLElement) {
        const { top, height } = before.getBoundingClientRect()

        if (top + height > 0) {
          shown = false
        }
      }

      if (after instanceof HTMLElement) {
        const { bottom } = after.getBoundingClientRect()

        if (bottom < 0) {
          shown = false
        }
      }

      this.setState({ shown })
    })
  }

  componentWillUnmount() {
    this.events.cleanUp()
  }

  render(props) {
    const {
      hiddenBeforeElement,
      hiddenAfterElement,
      children,
      className,
      ...passthruProps
    } = this.props

    const { shown } = this.state

    return (
      <aside
        className={`sticky-snackbar ${className ? className : ""} ${
          shown ? "shown" : ""
        }`}
        {...passthruProps}
      >
        <div className="inner">{children}</div>
      </aside>
    )
  }
}

/**
 * Compose
 */
const StickySnackbar = StickySnackbarComponent

export default StickySnackbar
