import React from "react"
import Anchor from "../../core/components/anchor"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import Row from "../../core/components/row"

/** Download component */
function DownloadComponent({ children, buttons }) {
  return (
    <Row id="download" className="download">
      <Block className="block-media">{children}</Block>
      <Block className="block-content downloads">
        <h3 className="title">Download</h3>
        <div className="content">
          {buttons &&
            buttons.map(({ label, url }) => (
              <Button key={url} url={url} image="icons/download.svg">
                Download {label}
              </Button>
            ))}
        </div>
      </Block>
      <Block className="block-content contact">
        <div className="content">
          <p>
            Please <Anchor to="/contact">contact us</Anchor> - we are ready to
            help make your vision a reality, wherever you are in your wine
            showcasing journey.
          </p>
          <p>
            Our design team will work with you to make your vision a reality:
            from concept through design, fabrication, and installation.
          </p>
        </div>
      </Block>
    </Row>
  )
}

/**
 * Compose
 */
const Download = DownloadComponent

export default Download
