// @flow

import React from "react"
import Anchor from "../../core/components/anchor"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"

type Finish = {
  name: string,
  image: string,
  gallery: Array<any>,
}

type Feature = {
  name: string,
  image: string,
  finishes: Array<Finish>,
}

// <ProductDetails />
type Props = {
  data: {
    root: string,
    features: Array<Feature>,
  },
}

type State = {
  feature: number,
  finish: number,
  selectedIndex: number,
}

/** Product Details component */
class ProductDetailsComponent extends React.Component<Props, State> {
  gallery1Element: ?HTMLDivElement
  gallery2Element: ?HTMLDivElement
  gallery1Ref: { current: any } = { current: null }
  gallery2Ref: { current: any } = { current: null }

  state = {
    feature: 0,
    finish: 0,
    selectedIndex: 0,
  }

  navigate(key, i) {
    return () => {
      const { features } = this.props.data || []
      const sliders = [this.gallery1Element, this.gallery2Element].filter(
        (slider) => slider
      )

      // set feature/finish/index
      let feature = this.state.feature
      let finish = this.state.finish

      // set feature
      if (key === "feature") {
        feature = i
      }

      // reset to zero if no feature found
      if (!features[i]) {
        feature = 0
      }

      // set finish
      if (key === "finish") {
        finish = i
      }

      // reset to zero if no finish found
      if (!features[feature].finishes[finish]) {
        finish = 0
      }

      // tear down slider
      sliders.forEach((slider) => slider.tearDown())

      // update state and rebuild slider
      // make sure a slide is always selected
      // even if sliders have different numbers of cells
      this.setState({ feature, finish }, () => {
        const { features } = this.props.data
        let { selectedIndex } = this.state
        if (!features[feature].finishes[finish].gallery[selectedIndex]) {
          selectedIndex = features[feature].finishes[finish].gallery.length - 1
        }

        sliders.forEach((slider) => slider.rebuild(selectedIndex))
      })
    }
  }

  componentDidMount() {
    const { data } = this.props
    const { features } = data || {}

    this.setState({
      feature: 0,
      finish: 0,
    })
  }

  render() {
    const { data } = this.props
    const { rootImageDir, features } = data || {}
    const { feature: featureIndex, finish: finishIndex } = this.state || {}

    let feature = features[featureIndex] || null
    let finish = null
    if (feature && feature.finishes) {
      finish = feature.finishes[finishIndex] || {}
    }

    return (
      <>
        <Row id="display-features" className="display-features">
          <Block className="block-content">
            <h2 className="title">
              Display
              <br /> Features
            </h2>
            <div className="content">
              {features && (
                <Slider
                  flickityProps={{
                    //reloadOnUpdate: true,
                    sideScroller: true,
                    options: {
                      freeScroll: true,
                      contain: true,
                      wrapAround: false,
                    },
                  }}
                  className="features"
                >
                  {features.map(({ name, image }, i) => (
                    <div
                      className={`feature ${
                        i === featureIndex ? "is-active" : ""
                      }`}
                      key={i}
                      onClick={this.navigate("feature", i).bind(this)}
                    >
                      <h3 className="title">{name}</h3>
                      <Decoration
                        image={rootImageDir + image}
                        disableLazyLoad={true}
                      />
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </Block>
        </Row>

        {feature && finish && (
          <Row id="product-details">
            <Block className="block-media">
              <Slider
                ref={(gallery1Element) =>
                  (this.gallery1Element = this.gallery1Ref.current =
                    gallery1Element)
                }
                flickityProps={{
                  //reloadOnUpdate: true,
                  options: {
                    cellAlign: "center",
                    wrapAround: true,
                    prevNextButtons: true,
                    imagesLoaded: false,
                    setGallerySize: false,
                  },
                }}
                onChange={((index) => {
                  this.setState({ selectedIndex: index })
                }).bind(this)}
                className="products"
              >
                {[...finish.gallery].map((image, i) => (
                  <DecorationFluid key={i} image={rootImageDir + image} />
                ))}
              </Slider>

              <Slider
                ref={(gallery2Element) =>
                  (this.gallery2Element = this.gallery2Ref.current =
                    gallery2Element)
                }
                flickityProps={{
                  //reloadOnUpdate: true,
                  options: {
                    cellAlign: "left",
                    contain: true,
                    wrapAround: false,
                    imagesLoaded: true,
                    setGallerySize: false,
                    asNavFor: this.gallery1Ref,
                  },
                }}
                className="products-nav"
              >
                {[...finish.gallery].map((image, i) => (
                  <DecorationFluid key={i} image={rootImageDir + image} />
                ))}
              </Slider>
            </Block>
            <Block className="block-content">
              <dl className="definition-items">
                {feature.details.map(({ title, content }, i) => (
                  <div key={i} className="definition-item">
                    <dt className="title">{title}</dt>
                    <dd className="content">
                      {content instanceof Array
                        ? content.map((line, i) => (
                            <React.Fragment key={i}>
                              {line} <br />
                            </React.Fragment>
                          ))
                        : content}
                    </dd>
                  </div>
                ))}

                <div className="definition-item">
                  <dt className="title">Finishes</dt>
                  <dd className="content">
                    <Navigation className="finishes">
                      {feature.finishes.map(({ name, image }, i) => (
                        <Button
                          key={i}
                          image={rootImageDir + image}
                          onClick={this.navigate("finish", i).bind(this)}
                          className={i === this.state.finish ? "active" : ""}
                        >
                          {name}
                        </Button>
                      ))}
                    </Navigation>
                  </dd>
                </div>

                <div className="definition-item">
                  <dt className="title">Custom Finishes</dt>
                  <dd className="content">
                    Custom Finishes Available
                    <Button
                      to="contact"
                      image="icons/arrow-right.svg"
                      className="custom-finishes-anchor"
                    >
                      Request Pricing
                    </Button>
                  </dd>
                </div>
              </dl>
            </Block>
          </Row>
        )}
      </>
    )
  }
}

/**
 * Compose
 */
const ProductDetails = ProductDetailsComponent

export default ProductDetails
