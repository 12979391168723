import React, { useRef } from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import Download from "../../client/compositions/download"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import ProductDetails from "../../client/compositions/product-details"
import StickySnackbar from "../../client/compositions/sticky-snackbar"
import Row from "../../core/components/row"
import { ProductsTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

const inspireFreeStandingFeatures = [
  {
    name: "Single/Single Standard",
    image: "/single-single/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Single/Single",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/single-single/brushed-stainless-steel/product-01.medium.jpg",
          "/single-single/brushed-stainless-steel/product-02.medium.jpg",
          "/single-single/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/single-single/black-satin/product-01.medium.jpg",
          "/single-single/black-satin/product-02.medium.jpg",
          "/single-single/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Double/Single Standard",
    image: "/double-single/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Double/Single",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/double-single/brushed-stainless-steel/product-01.medium.jpg",
          "/double-single/brushed-stainless-steel/product-02.medium.jpg",
          "/double-single/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/double-single/black-satin/product-01.medium.jpg",
          "/double-single/black-satin/product-02.medium.jpg",
          "/double-single/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Double/Double Standard",
    image: "/double-double/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Double/Double",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/double-double/brushed-stainless-steel/product-01.medium.jpg",
          "/double-double/brushed-stainless-steel/product-02.medium.jpg",
          "/double-double/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/double-double/black-satin/product-01.medium.jpg",
          "/double-double/black-satin/product-02.medium.jpg",
          "/double-double/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Triple/Single Standard",
    image: "/triple-single/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Triple/Single",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/triple-single/brushed-stainless-steel/product-01.medium.jpg",
          "/triple-single/brushed-stainless-steel/product-02.medium.jpg",
          "/triple-single/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/triple-single/black-satin/product-01.medium.jpg",
          "/triple-single/black-satin/product-02.medium.jpg",
          "/triple-single/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Triple/Double Standard",
    image: "/triple-double/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Triple/Double",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/triple-double/brushed-stainless-steel/product-01.medium.jpg",
          "/triple-double/brushed-stainless-steel/product-02.medium.jpg",
          "/triple-double/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/triple-double/black-satin/product-01.medium.jpg",
          "/triple-double/black-satin/product-02.medium.jpg",
          "/triple-double/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Triple/Triple Standard",
    image: "/triple-triple/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Triple/Triple",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/triple-triple/brushed-stainless-steel/product-01.medium.jpg",
          "/triple-triple/brushed-stainless-steel/product-02.medium.jpg",
          "/triple-triple/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/triple-triple/black-satin/product-01.medium.jpg",
          "/triple-triple/black-satin/product-02.medium.jpg",
          "/triple-triple/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Single/Single Offset",
    image: "/single-single-offset/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Single/Single",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/single-single-offset/brushed-stainless-steel/product-01.medium.jpg",
          "/single-single-offset/brushed-stainless-steel/product-02.medium.jpg",
          "/single-single-offset/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/single-single-offset/black-satin/product-01.medium.jpg",
          "/single-single-offset/black-satin/product-02.medium.jpg",
          "/single-single-offset/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Sprial",
    image: "/spiral/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Single/Single",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/spiral/brushed-stainless-steel/product-01.medium.jpg",
          "/spiral/brushed-stainless-steel/product-02.medium.jpg",
          "/spiral/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/spiral/black-satin/product-01.medium.jpg",
          "/spiral/black-satin/product-02.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Single/Single Alternating",
    image: "/single-single-alternating/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Single/Single",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/single-single-alternating/brushed-stainless-steel/product-01.medium.jpg",
          "/single-single-alternating/brushed-stainless-steel/product-02.medium.jpg",
          "/single-single-alternating/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/single-single-alternating/black-satin/product-01.medium.jpg",
          "/single-single-alternating/black-satin/product-02.medium.jpg",
          "/single-single-alternating/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Double/Double Offset",
    image: "/double-double-offset/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "1’, 2’, 3’",
      },
      {
        title: "Bracket Depth",
        content: "Double/Double",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 48 bottles",
          "4’ column up to 66 bottles",
          "8’ column up to 132 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/double-double-offset/brushed-stainless-steel/product-01.medium.jpg",
          "/double-double-offset/brushed-stainless-steel/product-02.medium.jpg",
          "/double-double-offset/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/double-double-offset/black-satin/product-01.medium.jpg",
          "/double-double-offset/black-satin/product-02.medium.jpg",
          "/double-double-offset/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
]

/**
 * Product: Inspire Floor-to-ceiling Page
 * @see https://zpl.io/2j6lyzq
 */
function ProductInspireFreeStandingPage() {
  const mainSlider = useRef()

  return (
    <article
      id="page-product-inspire-free-standing"
      className="page-content product-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            Inspire
            <br />
            Collection
          </>
        }
        content={
          <>
            <p>
              Minimal metal racks securely
              <br /> cradle your wine without
              <br /> visual intrusion.
            </p>
            <Navigation>
              <Anchor to="/products/inspire/wall-mounted/">Wall-mounted</Anchor>
              <Anchor to="/products/inspire/free-standing/">
                Floor-to-ceiling
              </Anchor>
            </Navigation>
          </>
        }
        className="flipped"
      >
        <DecorationFluid image="product-inspire-free-standing/banner.jpg" />
      </Banner>

      <StickySnackbar
        id="product-options"
        hiddenBeforeElement="#banner"
        hiddenAfterElement="#slim-fit"
      >
        <Navigation>
          <Anchor to="/products/inspire/wall-mounted/">Wall-mounted</Anchor>
          <Anchor to="/products/inspire/free-standing/">
            Floor-to-ceiling
          </Anchor>
        </Navigation>
      </StickySnackbar>

      <ProductDetails
        data={{
          rootImageDir: "/product-inspire-free-standing/features",
          features: inspireFreeStandingFeatures,
        }}
      />

      <Row id="display-styles">
        <Block>
          <h4 className="title">
            Display
            <br />
            Styles
          </h4>
          <ul className="items">
            <li className="item">
              <h4 className="title">Standard</h4>
              <DecorationFluid image="product-inspire-free-standing/display-01.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Alternating</h4>
              <DecorationFluid image="product-inspire-free-standing/display-02.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Spiral</h4>
              <DecorationFluid image="product-inspire-free-standing/display-03.medium.jpg" />
            </li>
          </ul>
        </Block>
      </Row>

      <Row id="slim-fit" className="content-plus-media">
        <Block className="block-content">
          <h2 className="title" style={{ whiteSpace: "nowrap" }}>
            Infinite
            <br /> Versatility
          </h2>
          <div className="content">
            Floor-to-ceiling modular metal racks can be customized to create the
            ultimate wine showcasing experience. Our Inspire collection can be
            customized to meet your desired height, width and depth. Create up
            to 50 different configurations for showcasing wine, including a
            custom angled presentation display. Integrate with wood to create a
            transitional and modern look.
          </div>
          <Button to="/contact" image="icons/arrow-right.svg">
            Contact
          </Button>
        </Block>
        <Block className="block-media">
          <Overlap
            style={{
              width: "46vw",
              height: "52vw",
            }}
            sizes={[
              { w: "67%", h: "83%" },
              { w: "50%", h: "49%" },
            ]}
          >
            <DecorationFluid image="product-inspire-free-standing/versatile-01.medium.jpg" />
            <DecorationFluid image="product-inspire-free-standing/versatile-02.small.jpg" />
          </Overlap>
        </Block>
      </Row>

      <Download
        buttons={[
          { label: "Catalogue", url: "http://google.com" },
          {
            label: "CAD files",
            url: "https://www.dropbox.com/sh/kvr609p0y3nqeto/AAA9Qs6chOqTiKP_tmNP_Wj_a?dl=1",
          },
        ]}
      >
        <DecorationFluid image="/product-inspire-free-standing/contact.small.jpg" />
      </Download>
      <ProductsTaglineMenu productSlug="product-inspire-wall-mounted" />
    </article>
  )
}

export default ProductInspireFreeStandingPage
